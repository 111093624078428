import { createRouter, createWebHistory } from 'vue-router';
import { getEnvironmentVariable } from '@/utils/config';
import Flow from '@/Flow.vue';
import Problem from '@/Problem.vue';

function handleRedirectDefault(to) {
  if (to.query['return_to']) {
    setTimeout(() => window.location.href = to.query['return_to'], 250);
  } else {
    setTimeout(() => window.location.href = getEnvironmentVariable('VUE_APP_APP_URL'), 250);
  }
}

const routes = [
  {
    path: '/',
    redirect: handleRedirectDefault,
  },
  {
    name: 'Problem',
    path: '/problem',
    component: Problem
  },
  {
    name: 'Error',
    path: '/error',
    component: Flow
  },
  {
    name: 'Login',
    path: '/login',
    component: Flow
  },
  {
    name: 'Logout',
    path: '/logout',
    component: Flow,
  },
  {
    name: 'Recovery',
    path: '/recovery',
    component: Flow
  },
  {
    name: 'Registration',
    path: '/registration',
    component: Flow,
  },
  {
    name: 'Settings',
    path: '/settings',
    component: Flow,
  },
  {
    name: 'Verification',
    path: '/verification',
    component: Flow,
  },
];

export default createRouter({
  history: createWebHistory(),
  routes
});
