<template>
  <div>
    <h1 class="text-center text-gray-600 text-4xl mb-12">
      We encountered a problem
    </h1>
    <div class="relative flex justify-center items-center w-2/3 m-auto">
      <img class="w-full" src="@/assets/server-status.svg" />
      <img class="absolute w-2/6 bottom-4 right-4 md:bottom-8 md:right-12" src="@/assets/AddifyLogo_Transparent.png" />
    </div>
    <p class="text-center text-gray-600 text-lg mt-8">
      Someone on our team is working to resolve the issue.
      <br />
      <span v-if="websiteUrl">
        Visit our <a :href="websiteUrl" class="font-bold hover:underline">website.</a>
      </span>
    </p>
  </div>
</template>

<script>
import { getEnvironmentVariable } from '@/utils/config';

export default {
  computed: {
    websiteUrl() {
      return getEnvironmentVariable(
        'VUE_APP_LULA_WEBSITE_URL'
      );
    }
  }
}
</script>
