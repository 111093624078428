<template>
  <div>
    <fieldset
      class="text-input-fieldset"
      :data-testid="`node/input/${node.attributes.name}`"
      v-if="node.type == 'input'"
    >
      <label class="font-bold text-gray-800" v-if="getLabelForNode(node)">
        <span class="typography-h3">
          {{ getLabelForNode(node) }}
        </span>
      </label>
      <button
        :name="node.attributes.name"
        :type="node.attributes.type"
        :value="node.attributes.value"
        :disabled="node.attributes.disabled"
        class="
          w-full
          rounded-full
          bg-lula-gradient
          text-white
          font-bold
          hover:bg-lula-gradient-alt
          mt-4
          text-base
        "
        v-if="getTypeForNode(node) === 'signin'"
      >
        {{ titleCase(node.meta.label.text) }}
      </button>
      <button
        :name="node.attributes.name"
        :type="node.attributes.type"
        :value="node.attributes.value"
        :disabled="node.attributes.disabled"
        @click.prevent="clickRegister"
        class="
          flex
          justify-center
          items-center
          w-full
          rounded-full
          text-gray-600
          bg-white
          border
          mt-0
          text-base
          hover:bg-gray-50
        "
        v-else-if="getTypeForNode(node) === 'register'"
      >
        <span>{{ titleCase(node.meta.label.text) }}</span>
      </button>
      <button
        :name="node.attributes.name"
        :type="node.attributes.type"
        :value="node.attributes.value"
        :disabled="node.attributes.disabled"
        class="
          flex
          justify-center
          items-center
          w-full
          rounded-full
          text-white
          bg-black
          font-bold
          border
          mt-0
          text-xs
          sm:text-base
        "
        v-else-if="getTypeForNode(node) === 'apple'"
      >
        <img class="w-6 mx-2" src="@/assets/apple-logo.svg" />
        <span>{{ titleCase(node.meta.label.text) }}</span>
      </button>
      <button
        :name="node.attributes.name"
        :type="node.attributes.type"
        :value="node.attributes.value"
        :disabled="node.attributes.disabled"
        class="
          flex
          justify-center
          items-center
          w-full
          rounded-full
          bg-white
          text-gray-600
          border
          mt-0
          text-xs
          sm:text-base
        "
        v-else-if="getTypeForNode(node) === 'google'"
      >
        <img class="w-6 mx-2" src="@/assets/google-logo.png" />
        <span>{{ titleCase(node.meta.label.text) }}</span>
      </button>
      <button
        :name="node.attributes.name"
        :type="node.attributes.type"
        :disabled="node.attributes.disabled"
        :value="node.attributes.value"
        class="
          w-full
          rounded-full
          bg-lula-gradient
          text-white
          font-bold
          hover:bg-lula-gradient-alt
          mt-1
          text-base
        "
        v-else-if="getTypeForNode(node) === 'recovery'"
      >
        Send Password Reset
      </button>
      <input
        class="text-input"
        :name="node.attributes.name"
        :type="node.attributes.type"
        :value="node.attributes.value"
        v-else />
    </fieldset>
    <img
      :src="node.attributes.src"
      :width="node.attributes.width"
      :height="node.attributes.height"
      :alt="node?.meta?.label?.text"
      data-testid="node/image/{{node.attributes.id}}"
      v-else-if="node.type === 'img'"
    />
    <div class="relative my-4" v-else-if="node.type === 'break'">
      <hr />
      <div class="absolute w-full flex jusify-center -top-2.5">
        <span class="m-auto bg-white px-4 text-sm text-gray-600"> OR </span>
      </div>
    </div>
    <div
      data-testid="node/text/{{node.attributes.id}}"
      v-else-if="node.type === 'text'"
    >
      <p
        style="margin-bottom: 0.5rem"
        data-testid="node/text/{{node.attributes.id}}/label"
        class="typography-paragraph"
      >
        {{ node?.meta?.label?.text }}
      </p>
      <!-- lookup_secret -->
      <div
        class="container-fluid"
        data-testid="node/text/{{node.attributes.id}}/text"
        v-if="node.attributes.text.id === '1050015'"
      >
        <div class="row">
          <div
            data-testid="node/text/{{node.attributes.id}}/lookup_secret"
            class="col-xs-3 recovery-code"
            v-for="secret in node.attributes.text.context.secrets"
            :key="secret.text"
          >
            <code>{{ secret.id === "1050014" ? "Used" : text }}</code>
          </div>
        </div>
        <!--Recovery Code-->
      </div>
      <pre style="margin-top: 0" v-else>
        <code
          data-testid="node/text/{{node.attributes.id}}/text">{{node.attributes.text.text}}
        </code>
      </pre>
    </div>
  </div>
</template>

<script>
import { titleCase } from "@/utils/strings";

function getLabelForNode(node) {
  const labelText = node.meta?.label?.text;
  switch (labelText) {
    case "ID": {
      return "Email";
    }
    case "Sign in":
    case "Sign in with apple":
    case "Sign in with google":
    case "Sign up":
    case "Sign up with apple":
    case "Sign up with google":
    case "Link google":
    case "Link apple":
    case "Save":
    case "Register":
    case "Submit": {
      return "";
    }
    default: {
      if(node.attributes?.name === 'email') {
        return 'Email';
      }
      return labelText || "";
    }
  }
}

function getTypeForNode(node) {
  const type = node.attributes?.type;
  const value = node.attributes?.value;
  if (type === "submit" && value === "register") {
    return "register";
  }
  if (type === "submit" && value === "password") {
    return "signin";
  }
  if (type === "submit" && value === "google") {
    return "google";
  }
  if (type === "submit" && value === "apple") {
    return "apple";
  }
  if (type === "submit" && value === "link") {
    return "recovery";
  }
  return null;
}

export default {
  props: ["node"],
  setup() {
    return {
      getLabelForNode,
      getTypeForNode,
      titleCase,
      clickRegister() {
        const url = new URL(window.location.href);
        url.pathname = "/registration";
        url.searchParams.delete('flow');
        window.location.href = url.toString();
      }
    };
  },
};
</script>
