<template>
  <header>
    <div>
      <transition name="fade">
        <div
          class="
            flex
            items-center
            px-4
            bg-white
            w-full
            border-t border-b
          "
          v-if="false"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="
              transition
              cursor-pointer
              text-gray-800
              hover:text-gray-600
              h-6
              w-6
            "
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            @click="banner = false"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
          <p class="w-full text-center p-4 font-bold text-gray-800">
            <a
              :href="extensionLink"
              target="_blank"
              class="cursor-pointer text-lula hover:underline">
                Click Here
            </a>
            for GetAddify's Free Driver Vetting Software!
          </p>
        </div>
      </transition>
    </div>
  </header>
  <main class="p-4 flex justify-center items-center w-screen h-screen">
    <router-view />
  </main>
</template>

<script>
import { ref, watch, computed } from "vue";
const { detect } = require('detect-browser');

export default {
  setup() {
    const bannerKey = "showRegistrationBanner";
    const banner = ref(
      window.localStorage.getItem(bannerKey) === null
        ? true
        : window.localStorage.getItem(bannerKey) === "true"
    );
    watch(banner, () => {
      window.localStorage.setItem(bannerKey, banner.value);
    });

    return {
      banner,
      name: "GetAddify Identity",
      extensionLink: computed(() => {
        const browser = detect();
        if(browser.name === 'safari') {
          return 'https://apps.apple.com/app/id1621350368';
        }
        return 'https://chrome.google.com/webstore/detail/getaddify/opmofoabhhdachjoinpoachbgdidhamo';
      })
    };
  },
};
</script>
