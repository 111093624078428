<template>
  <div
    class="
      bg-white
      shadow-xl
      rounded-2xl
      px-2
      py-8
      text-2xl
      w-full
      md:w-2/3
      lg:w-1/2
      xl:w-1/3
    "
    v-if="flow"
  >
    <!-- Attempted to login to an address that is not yet verified -->
    <div class="flex flex-col h-1/2 w-full flex justify-center items-center" v-if="errorMessage?.id === 4000010">
      <img class="w-2/3" src="@/assets/AddifyLogo_Transparent.png" />
      <p
        class="text-base text-gray-600 text-center mt-4 px-8"
      >
        Your email address has not been verified yet. Please check your inbox to verify your account and get started.
      </p>
      <div class="flex w-full justify-between items-center pt-2">
        <button @click="onResendEmail" class="
            bg-lula-gradient
            font-bold
            hover:bg-lula-gradient-alt
            m-2
            rounded-full
            text-base
            text-white
            w-full
        ">
          Resend Email
        </button>
        <button @click="onRetryLogin" class="
          flex
          justify-center
          items-center
          w-full
          rounded-full
          text-gray-600
          bg-white
          border
          mt-0
          text-base
          hover:bg-gray-50
        ">
          Retry Login
        </button>
      </div>
    </div>

    <!-- Default login page and error handling -->
    <div v-else>
      <div class="flex flex-col h-1/2 w-full flex justify-center items-center">
        <img class="w-2/3" src="@/assets/AddifyLogo_Transparent.png" />
        <p
          :class="{
            'text-red-500': errorMessage.type === 'error',
            'text-gray-600': errorMessage.type !== 'error',
          }"
          class="text-base text-center mt-4 px-8"
          v-if="errorMessage"
        >
          {{ errorMessage.text }}
        </p>
      </div>
      <form
        @submit="handleSubmit"
        :method="flow.ui.method"
        :action="flow.ui.action"
        :class="{ 'pt-4': flowType === 'recovery', 'pt-0': errorMessage }"
        class="p-8 pb-0"
      >
        <div class="mb-2" v-for="node in nodes" :key="node.attributes.name">
          <Node :node="node" />
        </div>
      </form>
      <div
        class="flex justify-center text-base text-gray-600"
        v-if="flowType === 'login'"
      >
        <a class="hover:underline" :href="getRedirectPath('recovery')">
          Forgot password
        </a>
      </div>
      <div
        class="flex justify-center text-base text-gray-600"
        v-if="flowType === 'recovery' || flowType === 'registration'"
      >
        <a class="hover:underline" :href="getRedirectPath('login')">
          Sign in to account
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { handleFlowType } from "@/utils/flow";
import { getEnvironmentVariable } from "@/utils/config";
import { submitSession } from '@/utils/session';
import { useToast } from "vue-toastification";
import Node from "@/Node.vue";

function hydrateNodeList(nodes, { email, oidc, flowType }) {
  function isRegistrationEmailNode(node) {
    return (
      node.attributes?.type === "email" &&
      node.attributes?.node_type === "input"
    );
  }
  function isSubmitNode(node) {
    return (
      node.attributes?.type === "submit" && node.attributes.value === "password"
    );
  }
  const nodemaps = nodes.map((node) => {
    if (isRegistrationEmailNode(node)) {
      return {
        ...node,
        attributes: {
          ...(node.attributes || {}),
          value: email || node.attributes?.value,
        },
      };
    }
    return node;
  });
  if (flowType === "login") {
    const submitIndex = nodemaps.findIndex((node) => isSubmitNode(node));
    if (submitIndex) {
      nodemaps.splice(submitIndex + 1, 0, {
        attributes: {
          name: "register",
          type: "submit",
          value: "register",
          required: false,
          disabled: false,
          node_type: "button",
        },
        group: "password",
        meta: {
          label: {
            id: -1,
            text: "Register",
            type: "info",
          },
        },
        type: "input",
      });
    }
  }
  if (flowType === "registration") {
    const submitIndex = nodemaps.findIndex((node) => isSubmitNode(node));
    if (submitIndex) {
      nodemaps.splice(submitIndex, 0, {
        attributes: {
          name: "confirm",
          type: "password",
          required: true,
          disabled: false,
          node_type: "input",
        },
        group: "password",
        meta: {
          label: {
            id: -1,
            text: "Confirm Password",
            type: "info",
          },
        },
        type: "input",
      });
    }
  }
  if ((oidc || []).length > 0) {
    nodemaps.push({
      attributes: { name: "break" },
      type: "break",
    });
  }
  return nodemaps;
}

function filterNodeList(nodes, { flowType }) {
  return nodes.filter((node) => {
    if (flowType === "settings") {
      switch (node.group) {
        case "totp":
        case "profile":
        case "lookup_secret": {
          return false;
        }
        case "default":
          return node.attributes.name === "csrf_token";
        default: {
          return true;
        }
      }
    }
    return true;
  });
}

export default {
  components: { Node },
  setup() {
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();
    const flow = ref(null);
    const flowType = ref(null);

    const kratos = getEnvironmentVariable("VUE_APP_KRATOS_URL");
    const handle = handleFlowType(kratos, router, toast);

    onMounted(async () => {
      const query = router.currentRoute.value.query;
      const sessionId = query['session_id']
      const sessionChallenge = query['session_challenge']
      if (sessionId && sessionChallenge) {
        const sessionApiUrl = getEnvironmentVariable("VUE_APP_SESSION_API_URL");
        if (await submitSession(sessionApiUrl, sessionId, sessionChallenge)) {
          if (query['return_to']) {
            setTimeout(() => window.location.href = query['return_to'], 250);
          } else {
            setTimeout(() => window.location.href = getEnvironmentVariable('VUE_APP_APP_URL'), 250);
          }
        }
      }
      flowType.value = router.currentRoute.value.name.toLowerCase();
      flow.value = await handle(flowType.value, route.query.flow);
    });

    const onResendEmail = async () => {
      // We don't have their email available, so send them to the self serve verification page
      const query = router.currentRoute.value.query;
      await router.push({
        path: '/verification',
        query: {
          return_to: query['return_to'],
          email: query['email'],
        },
      });
      router.go()
    }

    const onRetryLogin = async () => {
      const query = router.currentRoute.value.query;
      await router.push({
        path: '/login',
        query: {
          return_to: query['return_to'],
          email: query['email'],
        },
      });
      router.go();
    }

    return {
      flow,
      flowType,
      onResendEmail,
      onRetryLogin,
      nodes: computed(() => {
        const nodelist = filterNodeList(flow.value?.ui?.nodes || [], {
          flowType: flowType.value,
        });
        const nodes = hydrateNodeList(nodelist, {
          oidc: nodelist.filter((n) => n.group === "oidc"),
          flowType: flowType.value,
          email: route.query.email,
        });
        return [
          ...nodes.filter((n) => n.group !== "oidc"),
          ...nodes.filter((n) => n.group === "oidc").reverse(),
        ];
      }),
      getRedirectPath(pathname) {
        const url = new URL(window.location.href);
        url.pathname = `/${pathname}`;
        url.searchParams.delete("flow");
        if (!url.toString().includes("return_to")) {
          url.searchParams.set(
            "return_to",
            getEnvironmentVariable("VUE_APP_APP_URL")
          );
        }
        return url.toString();
      },
      errorMessage: computed(() => {
        const messages =
          flow?.value?.ui?.nodes?.map((n) => n.messages)?.flat() ?? [];
        const displayMessage = [
          ...messages,
          ...(flow?.value?.ui?.messages ?? []),
        ].pop();
        if (displayMessage) {
          return displayMessage;
        }
        if (window.localStorage.getItem("registrationSuccess")) {
          window.localStorage.removeItem("registrationSuccess");
          return {
            id: -1,
            text: "Registration was successful. Please check your email for confirmation.",
            type: "info",
          };
        }
        return null;
      }),
      handleSubmit(evt) {
        toast.clear();
        const form = evt.target;
        let email = form.querySelector('input[name="password_identifier"]');
        if (!email) {
          email = form.querySelector('input[name="traits.email"]');
        }
        const password = form.querySelector('input[name="password"]');
        const confirm = form.querySelector('input[name="confirm"]');
        if (evt.submitter.value === "password") {
          if (!email.value) {
            toast.error("Email required.");
            return evt.preventDefault();
          }
          if (!password.value) {
            toast.error("Password required.");
            return evt.preventDefault();
          }
        }
        if (confirm.value !== password.value) {
          toast.error("Passwords do not match.");
          evt.preventDefault();
        }
        if (flowType.value === "registration") {
          window.localStorage.setItem("registrationSuccess", true);
        }
      },
    };
  },
};
</script>
