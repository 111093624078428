import axios from "axios";

function getSelfServiceFlow(base, handleErr, router) {
    return async (flowType, flowId) => {
        try {
            const params = { id: flowId };
            const redirect = router.currentRoute?.value?.query?.return_to;
            if(redirect) {
              params['return_to'] = redirect
            }
            const flow = await axios({
                method: "GET",
                url: `${base}/self-service/${flowType}/flows`,
                params,
                withCredentials: true,
            });
            return flow.data;
        } catch (ex) {
            handleErr(flowType, ex)
        }
    }
}

function initializeSelfServiceFlow(base, handleErr, router) {
    return async (flowType) => {
        try {
          const params = {};
          const returnTo = router.currentRoute?.value?.query?.return_to;
          if (returnTo) {
            params['return_to'] = returnTo;
          }
          const flow = await axios({
                method: "GET",
                url: `${base}/self-service/${flowType}/browser`,
                params,
                withCredentials: true,
            });
            return flow.data;
        } catch (ex) {
            handleErr(flowType, ex)
        }
    }
}

function logoutSelfServiceFlow(base, handleErr) {
    return async () => {
        try {
            const logout = await axios({
                method: "GET",
                url: `${base}/self-service/logout/browser`,
                withCredentials: true,
            });
            window.location.href = logout.data.logout_url
            return
        } catch (ex) {
            console.log(ex);
            handleErr('logout', ex)
        }
    }
}


// A small function to help us deal with errors coming from fetching a flow.
function handleSelfServiceError(
    router,
    toast
) {
    return async (flowType, err) => {
        function waitAndRedirect(to = '/', time = 2500) {
          setTimeout(() => window.location.href = to, time);
        }
        switch (err.response?.data.error?.id) {
            case 'session_aal2_required':
                // 2FA is enabled and enforced, but user did not perform 2fa yet!
                window.location.href = err.response?.data.error.redirect_browser_to
                return
            case 'session_already_available':
                // User is already signed in, let's redirect them home!
                window.location.href = '/';
                return
            case 'session_refresh_required':
                // We need to re-authenticate to perform this action
                window.location.href = err.response?.data.error.redirect_browser_to
                return
            case 'self_service_flow_return_to_forbidden':
                // The flow expired, let's request a new one.
                toast.error('The return_to address is not allowed.');
                waitAndRedirect('/' + flowType);
                return
            case 'self_service_flow_expired':
                // The flow expired, let's request a new one.
                toast.error('Your interaction expired, please fill out the form again.')
                waitAndRedirect('/' + flowType);
                return
            case 'security_csrf_violation':
                // A CSRF violation occurred. Best to just refresh the flow!
                toast.error(
                    'A security violation was detected, please fill out the form again.'
                )
                waitAndRedirect('/' + flowType);
                return
            case 'security_identity_mismatch':
                // The requested item was intended for someone else. Let's request a new flow...
                await router.push('/' + flowType)
                return
            case 'browser_location_change_required':
                // Ory Kratos asked us to point the user to this URL.
                window.location.href = err.response?.data.error.redirect_browser_to
                return
            case 'session_inactive':
                // Session is inactive. Redirect the user to the login page.
                window.location.href = '/'
                return
        }

        switch (err.response?.status) {
            case 400:
                // Bad request, user already signed in
                window.location.href = '/'
                return
            case 401:
                // The user is unathenticated
                await router.push('/login')
                return
            case 404:
                // The user is unathenticated
                window.location.href = '/login'
                return
            case 410:
                // The flow expired, let's request a new one.
                await router.push('/' + flowType)
                return
        }

        // We are not able to handle the error? Return it.
        return await router.push({ name: 'Problem' });
    }
}

function handleSelfServiceFlow(base, router, toast) {
    const handleErr = handleSelfServiceError(router, toast);
    const getFlow = getSelfServiceFlow(base, handleErr, router);
    const initializeFlow = initializeSelfServiceFlow(base, handleErr, router)
    const logoutFlow = logoutSelfServiceFlow(base, handleErr)
    return async (flowType, flowId) => {
        if (flowType == 'logout') {
            return await logoutFlow()
        }
        if (flowId) {
            try {
                return await getFlow(flowType, flowId)
            } catch (ex) {
                console.log(ex)
            }
        }
        return await initializeFlow(flowType)
    }
}

export const handleFlowType = handleSelfServiceFlow
