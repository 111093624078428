import axios from "axios";

export const submitSession = async (base, id, challenge) => {
    if (!id || !challenge) {
        return false;
    }
    try {
        const response = await axios({
            method: "POST",
            url: `${base}/sessions/${id}/submit`,
            data: { challenge },
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
        });
        console.log(response);
        return true;
    } catch (ex) {
        console.log(ex);
        return false;
    }
};

