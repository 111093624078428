import axios from 'axios'

export async function getUser(kratosUrl) {
    try {
        const response = await axios({
            method: "GET",
            url: `${kratosUrl}/sessions/whoami`,
            withCredentials: true,
        });
        return response.data;
    } catch(err) {
        return null;
    }
}