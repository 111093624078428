import 'vue-toastification/dist/index.css'
import Toast from 'vue-toastification'
import './assets/tailwind.css'
import './assets/index.css'
import { createApp } from 'vue'
import router from './router'
import { getEnvironmentVariable } from './utils/config'
import { submitSession } from './utils/session'
import { getUser } from './utils/user'
import App from './App.vue'

const toastOptions = { toastClassName: 'bg-lula-gradient' }

async function main() {
    const authUrl = new URL(window.location.href);
    const returnToUrl = authUrl.searchParams.get('return_to') || getEnvironmentVariable('VUE_APP_APP_URL');
    const sessionId = authUrl.searchParams.get('session_id');
    const sessionChallenge = authUrl.searchParams.get('session_challenge');
    const sessionApiUrl = getEnvironmentVariable('VUE_APP_SESSION_API_URL');
    if (await submitSession(sessionApiUrl, sessionId, sessionChallenge)) {
        window.location.href = returnToUrl;
    } else if (!(authUrl.pathname.includes('logout') || authUrl.pathname.includes('settings')) && await getUser(getEnvironmentVariable('VUE_APP_KRATOS_URL'))) {
        window.location.href = returnToUrl;
    } else {
        const app = createApp(App)
        app.use(Toast, toastOptions);
        app.use(router);
        app.mount('#app');
    }
}

main();